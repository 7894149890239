import { Injectable } from '@angular/core'
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http'
import { HandleError, HttpErrorHandler } from '@core/services/api/http-error-handler.service'
import { JsonldResponse } from '@core/interfaces/api/jsonld-response'
import { catchError, map, Observable } from 'rxjs'
import { environment } from '@env/environment'
import { TableLazyLoadEvent } from 'primeng/table'
import { FilterMetadata } from 'primeng/api'
import { IS_LOADER_DISABLED } from '@core/services/api/loading-interceptor'

@Injectable({ providedIn: 'root' })
export class ApiService {
  private httpHeaders: HttpHeaders = new HttpHeaders({
    'Content-Type':  'application/json',
  })

  private readonly handleError: HandleError
  protected endpoint: string = ''
  protected totalItems: number = 0

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
  ) {
    this.handleError = httpErrorHandler.createHandleError(this.constructor.name)
  }

  public _getTotalItems(): number { return this.totalItems }

  protected _getCollection<T>(event: TableLazyLoadEvent, disableLoader: boolean = false): Observable<T[]> {
    const url: string = environment.backendUrl + this.endpoint + this.generateCollectionQueryParams(event)

    let context = undefined
    if (disableLoader) {
      context = new HttpContext().set(IS_LOADER_DISABLED, true)
    }

    return this.http.get<JsonldResponse<T>>(url, { headers: this.httpHeaders, context: context })
      .pipe(
        map(result => {
          this.totalItems = result['hydra:totalItems'] || 0
          return result['hydra:member']
        }),
        catchError(this.handleError('_getCollection', []))
      )
  }

  protected _getCollectionFlat<T>(event: TableLazyLoadEvent, disableLoader: boolean = false): Observable<T[]> {
    const url: string = environment.backendUrl + this.endpoint + this.generateCollectionQueryParams(event)

    let context = undefined
    if (disableLoader) {
      context = new HttpContext().set(IS_LOADER_DISABLED, true)
    }

    return this.http.get<Array<T>>(url, { headers: this.httpHeaders, context: context })
      .pipe(
        map(result => {
          this.totalItems = result?.length || 0
          return result
        }),
        catchError(this.handleError('_getCollectionFlat', []))
      )
  }

  protected _getCollectionSelect<T>(filter: string, event: TableLazyLoadEvent | null = null, disableLoader: boolean = false): Observable<T[]> {
    let url: string = environment.backendUrl + this.endpoint + '/select'

    if (filter.length > 0) {
      if (!event) {
        event = { filters: { search: { value: filter } } }
      } else {
        if (event?.filters)
          event.filters['search'] = { value: filter }
        else
          event.filters = { search: { value: filter } }
      }
    }

    if (event) {
      url += this.generateCollectionQueryParams(event)
    }

    let context = undefined
    if (disableLoader) {
      context = new HttpContext().set(IS_LOADER_DISABLED, true)
    }

    return this.http.get<JsonldResponse<T>>(url, { headers: this.httpHeaders, context: context })
      .pipe(
        map(result => {
          this.totalItems = result['hydra:totalItems'] || 0
          return result['hydra:member']
        }),
        catchError(this.handleError('_getCollectionSelect', []))
      )
  }

  protected _getItem<T>(id: number): Observable<T> {
    const url: string = environment.backendUrl + this.endpoint + '/' + id

    // @ts-ignore
    return this.http.get<T>(url, { headers: this.httpHeaders })
      .pipe(
        catchError(this.handleError('_getItem', []))
      )
  }

  protected _get(endpoint: string, absoluteEndpoint: boolean = false, disableLoader: boolean = false): Observable<any> {
    let url: string = ''

    if (absoluteEndpoint) {
      if (endpoint.length > 0)
        url = environment.backendUrl + endpoint
      else
        throw new Error('Empty Endpoint')
    } else {
      url = environment.backendUrl + this.endpoint

      if (endpoint.length > 0)
        url += '/' + endpoint
    }

    let context = undefined
    if (disableLoader) {
      context = new HttpContext().set(IS_LOADER_DISABLED, true)
    }

    return this.http.get<any>(url, { context: context })
      .pipe(
        catchError(this.handleError('_get', []))
      )
  }

  protected _post(obj: object, endpoint: string | null = null, disableLoader: boolean = false): Observable<any> {
    let url: string = environment.backendUrl + this.endpoint

    if (endpoint)
      url = environment.backendUrl + endpoint

    let context = undefined
    if (disableLoader) {
      context = new HttpContext().set(IS_LOADER_DISABLED, true)
    }

    return this.http.post<object>(url, obj, { context: context })
      .pipe(
        catchError(this.handleError('_post', []))
      )
  }

  protected _patch(id: number, obj: object, endpoint: string | null = null, disableLoader: boolean = false): Observable<any> {
    let url: string = environment.backendUrl + this.endpoint + '/' + id

    if (endpoint)
      url = environment.backendUrl + endpoint + (id > 0 ? id : '')

    let context = undefined
    if (disableLoader) {
      context = new HttpContext().set(IS_LOADER_DISABLED, true)
    }

    return this.http.patch<object>(url, obj, { context: context })
      .pipe(
        catchError(this.handleError('_post', []))
      )
  }

  protected _delete(id: number, absoluteEndpoint: string = '', disableLoader: boolean = false): Observable<any> {
    let url: string = environment.backendUrl + this.endpoint + '/' + id

    if (absoluteEndpoint.length > 0) {
      url = environment.backendUrl + absoluteEndpoint + id
    }

    let context = undefined
    if (disableLoader) {
      context = new HttpContext().set(IS_LOADER_DISABLED, true)
    }

    return this.http.delete<any>(url, { headers: this.httpHeaders, context: context })
      .pipe(
        catchError(this.handleError('_delete', []))
      )
  }

  protected _download(endpoint: string, absoluteEndpoint: boolean = false, disableLoader: boolean = false, post: boolean = false, body: any = null): Observable<any> {
    let url: string = ''

    if (absoluteEndpoint) {
      if (endpoint.length > 0)
        url = environment.backendUrl + endpoint
      else
        throw new Error('Empty Endpoint')
    } else {
      url = environment.backendUrl + this.endpoint

      if (endpoint.length > 0)
        url += '/' + endpoint
    }

    let context = undefined
    if (disableLoader) {
      context = new HttpContext().set(IS_LOADER_DISABLED, true)
    }

    if (post) {
      return this.http.post(url, body, { context: context, observe: 'response', responseType: 'blob' })
    }

    return this.http.get(url, { context: context, observe: 'response', responseType: 'blob' })
  }

  protected downloadFile(response: any, fileName: string): void {
    // create file link in browser's memory
    const href: string = URL.createObjectURL(response.body)

    // create "a" HTML element with href to file & click
    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }

  private generateCollectionQueryParams(event: TableLazyLoadEvent) {
    let queryItems = []

    if (event.filters) {
      const entries = Object.entries(event.filters)

      for (let [ name, value ] of entries) {
        if (value !== null && typeof value !== 'undefined') {
          if (name === 'global' && 'value' in value) {
            queryItems.push(`search=${value?.value}`)
          }
          else if (Array.isArray(value)) {
            value.forEach((item: FilterMetadata) => {
              // prevent adding square brackets to query string either when they already exist or value is boolean
              name.includes('[') && name.includes(']') || typeof item.value === 'boolean'
                ? queryItems.push(`${name}=${item.value}`)
                : queryItems.push(`${name}[]=${item.value}`)
            })
          }
          else if (Array.isArray(value.value)) {
            for (const val of value.value) {
              queryItems.push(`${name}[]=${val}`)
            }
          }
          else {
            queryItems.push(`${name}=${value?.value}`)
          }
        }
      }
    }

    // add parameters for pagination
    if (typeof event.sortField !== 'undefined') {
      queryItems.push('order[' + event.sortField + ']=' + ((event.sortOrder === 1) ? 'asc' : 'desc'))
    }

    let rows: number = event?.rows || 30
    if (rows <= 0)
      rows = 30

    queryItems.push('itemsPerPage=' + rows)

    // calc page
    const page: number = ((event?.first || 0) / rows) + 1
    queryItems.push('page=' + page)

    return (queryItems.length > 0) ? '?' + queryItems.join('&') : ''
  }

  public getEndpoint(): string {
    return this.endpoint
  }
}
