import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class LoaderService {

  public loading$: Subject<boolean> = new Subject<boolean>()

  setLoading(loading: boolean): void {
    this.loading$.next(loading)
  }

}
