import { Injectable } from '@angular/core'
import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { finalize, Observable } from 'rxjs'
import { LoaderService } from '@core/services/api/loader.service'

export const IS_LOADER_DISABLED = new HttpContextToken<boolean>(() => false)

@Injectable({ providedIn: 'root' })
export class LoadingInterceptor implements HttpInterceptor {

  private totalRequests = 0

  constructor(private loaderService: LoaderService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.context.get(IS_LOADER_DISABLED)) {
      return next.handle(request)
    }

    this.totalRequests++

    this.loaderService.setLoading(true)

    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--

        if (this.totalRequests == 0) {
          this.loaderService.setLoading(false)
        }
      })
    )
  }
}
